import $ from '../core/Dom';

const init = () => {
    $('[data-native-share]').on('click', e => {
        e.preventDefault();
        try {
            navigator
                .share({
                    title: document.title,
                    url: window.location.href
                })
                .then(() => console.log('Shared successfully'))
                .catch(error => console.error('Sharing failed:', error));
        } catch (error) {
            console.error('Sharing failed:', error);
        }
    });
    if (navigator.share) {
        $('[data-native-share]').removeClass('hidden');
    }
};

export default {
    init
};
