import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { IMAGE_GALLERY_OPEN } from '../lib/events';
import agent from '../core/request';

export default (el, props) => {
    const dom = $(el);

    const onOpen = () => {
        agent
            .get(props.url)
            .end((error, res) => {
                if (error) {
                    console.error(error);
                } else {
                    Dispatch.emit(IMAGE_GALLERY_OPEN, { html: res.text, triggerButton: el });
                }
            });
    };

    const init = () => {
        console.log(props);
        dom.on('click', onOpen);
    };

    const destroy = () => {
        dom.off('click', onOpen);
    };

    return {
        init,
        destroy
    };
};
