import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

const loadScrollTrigger = require('bundle-loader?lazy&name=[name]!gsap/ScrollTrigger');

export default el => {
    const dom = $(el);
    const images = dom.find('[data-image]').nodes;
    const heading = dom.find('[data-heading]').get(0);
    const text = dom.find('[data-text]');

    let firstTimeObserver = null;

    const updateStickyTextTop = () => {
        const rect = heading.getBoundingClientRect();
        const offset = window.innerHeight * 0.18;
        text.css('top', `${rect.height + offset}px`);
    };

    const init = () => {
        Viewport.on('resize', updateStickyTextTop);
        firstTimeObserver = new IntersectionObserver(([{ isIntersecting }]) => {
            if (!isIntersecting) {
                return;
            }
            updateStickyTextTop();
            firstTimeObserver.unobserve(el);
            firstTimeObserver.disconnect();
            firstTimeObserver = null;
            loadScrollTrigger(module => {
                const ScrollTrigger = module.default;
                gsap.registerPlugin(ScrollTrigger);
                const margins = [-10, -8, -3];
                images.forEach((image, index) => {
                    const offsetY = margins[index];
                    gsap.to(image, {
                        y: `+=${offsetY}vh`,
                        scrollTrigger: {
                            trigger: el,
                            start: 'top top',
                            end: 'bottom bottom',
                            scrub: 2
                        }
                    });
                });

                ScrollTrigger.create({
                    trigger: el,
                    start: 'top top',
                    end: 'bottom bottom',
                    onEnter: () => {
                        console.log('onEnter');
                        el.classList.add('active');
                    },
                    onEnterBack: () => {
                        console.log('onEnterBack');
                        el.classList.add('active');
                    },
                    onLeave: () => {
                        console.log('onLeave');
                        el.classList.remove('active');
                    },
                    onLeaveBack: () => {
                        console.log('onLeaveBack');
                        el.classList.remove('active');
                    }
                });
            });
        });
        firstTimeObserver.observe(el);
    };

    const destroy = () => {
        observer.unobserve(el);
        observer = null;
        active = false;
        Viewport.off('resize', updateStickyTextTop);
    };

    return {
        init,
        destroy
    };
};
