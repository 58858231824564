import gsap from 'gsap';
import $ from '../core/Dom';
import shouldAnimate, { onReducedMotion } from '../lib/ReducedMotion';
import Dispatch from '../core/Dispatch';
import { SPLASH_COMPLETE } from '../lib/events';

const loadLottie = require('bundle-loader?lazy&name=[name]!lottie-web');

export default el => {
    const dom = $(el);
    const element = dom.find('[data-lottie]');
    const homepageHero = $('[data-homepage-hero]').get(0);

    let lottie = null;
    let animation = null;

    const destroy = () => {
        if (animation) {
            animation.removeEventListener('complete');
            animation.destroy();
        }
        el.parentNode.removeChild(el);
    };

    const onDestroySplash = () => {
        destroy();
        Dispatch.emit(SPLASH_COMPLETE);
    };

    const init = () => {
        if (shouldAnimate()) {
            loadLottie(l => {
                lottie = l;
                animation = lottie.loadAnimation({
                    container: element.get(0),
                    renderer: 'svg',
                    loop: false,
                    autoplay: true,
                    path: element.data('lottie')
                });
                animation.addEventListener('complete', () => {
                    window.vrsg.setCookie('splash_shown', '1', 10);
                    gsap.fromTo(homepageHero,
                        {
                            yPercent: 25
                        }, {
                            yPercent: 0,
                            duration: 1.25,
                            ease: 'power2.inOut'
                        });
                    gsap.to(el, {
                        yPercent: -100,
                        duration: 1.25,
                        ease: 'power2.inOut',
                        onComplete: onDestroySplash
                    });
                });
            });
        } else {
            destroy();
        }
    };

    return {
        init,
        destroy
    };
};
