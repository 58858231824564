import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

const loadScrollTrigger = require('bundle-loader?lazy&name=[name]!gsap/ScrollTrigger');

export default el => {
    const dom = $(el);
    const heading = dom.find('[data-heading]').get(0);
    const media = dom.find('[data-media]').get(0);
    const shader = dom.find('[data-shader]').get(0);
    const parallax = dom.find('[data-parallax]').get(0);
    const contactBanner = $('[data-contact-banner]').get(0);

    let fullRect = 'rect(0 0 0 0)';
    let clipRect = fullRect;
    let contracted = false;
    let observer;

    const updateClipRect = () => {
        const top = dom.css('paddingTop');
        const elementRect = el.getBoundingClientRect();
        const headingRect = heading.getBoundingClientRect();
        fullRect = `rect(0px ${elementRect.width}px ${elementRect.height}px 0px)`;
        clipRect = `rect(${top} ${headingRect.x + headingRect.width}px ${headingRect.top + window.scrollY}px ${headingRect.x}px)`;
        if (contracted) {
            gsap.set(media, { clipPath: clipRect });
        } else {
            gsap.set(media, { clipPath: fullRect });
        }
    };

    const onExpand = () => {
        contracted = false;
        dom.addClass('js-expanded');
        gsap.to(media, { clipPath: fullRect, duration: 0.6, ease: 'power2.inOut' });
        gsap.to(heading, { color: '#FFFFFF', duration: 0.6, ease: 'power2.inOut' });
        gsap.to(shader, { opacity: 0.5, duration: 0.6, ease: 'power2.inOut' });
        if (contactBanner) {
            contactBanner.ariaHidden = 'true';
        }
    };

    const onContract = () => {
        contracted = true;
        dom.removeClass('js-expanded');
        gsap.to(media, { clipPath: clipRect, duration: 0.6, ease: 'power2.out' });
        gsap.to(heading, { color: '#101212', duration: 0.6, ease: 'power2.ut' });
        gsap.to(shader, { opacity: 0, duration: 0.6, ease: 'power2.out' });
        if (contactBanner) {
            contactBanner.ariaHidden = 'false';
        }
    };

    const onIntersectionChange = entries => {
        if (entries.filter(entry => entry.isIntersecting).length) {
            if (contracted) {
                onExpand();
            }
        } else {
            onContract();
        }
    };

    const init = () => {
        document.fonts.ready.then(() => {
            updateClipRect();
            Viewport.on('resize', updateClipRect);
            observer = new IntersectionObserver(onIntersectionChange, {
                threshold: 0.65
            });
            observer.observe(el);
        });

        loadScrollTrigger(module => {
            const ScrollTrigger = module.default;
            gsap.registerPlugin(ScrollTrigger);
            gsap.to(parallax, {
                y: window.innerHeight * 0.2,
                scale: 1.2,
                scrollTrigger: {
                    start: 'top top',
                    end: 'bottom bottom',
                    scrub: 1
                }
            });
        });
    };

    const destroy = () => {
        Viewport.off('resize', updateClipRect);
    };

    return {
        init,
        destroy
    };
};
