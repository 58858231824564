export default el => {
    const video = el;

    let observer = null;
    let loaded = false;
    let playing = false;

    const timeupdate = () => {
        if (video.currentTime < 0.001) {
            return;
        }
        playing = true;
        loaded = true;
        video.removeEventListener('timeupdate', timeupdate);
        video.classList.remove('opacity-0');
    };

    const onIntersectionChange = entries => {
        if (entries.filter(entry => entry.isIntersecting).length) {
            if (loaded) {
                playing = true;
                video.play();
            } else {
                video.addEventListener('timeupdate', timeupdate);
                video.setAttribute('src', video.getAttribute('data-src'));
            }
        } else if (loaded && playing) {
            playing = false;
            video.pause();
        }
    };

    const init = () => {
        observer = new IntersectionObserver(onIntersectionChange);
        observer.observe(el);
    };

    const destroy = () => {
        if (observer) {
            observer.unobserve(el);
        }
        video.removeEventListener('timeupdate', timeupdate);
        playing = false;
        loaded = false;
    };

    return {
        init,
        destroy
    };
};
