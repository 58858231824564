import gsap from 'gsap';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import ScrollToTarget from './lib/ScrollToTarget';
import NativeShareButton from './lib/NativeShareButton';

gsap.defaults({ overwrite: 'auto' });
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollToPlugin);

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    lazySizes.init();
    ScrollToTarget.init();
    NativeShareButton.init();
};

require('doc-ready')(init);
