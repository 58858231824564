import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default el => {
    const dom = $(el);

    const headerLogo = $('[data-header-logo]').get(0);
    const footerLogo = dom.find('[data-footer-logo]').get(0);

    let observer = null;
    let footerRect = null;

    const onIntersectionChange = entries => {
        if (footerRect.y > window.innerHeight) {
            if (entries.filter(entry => entry.isIntersecting).length) {
                gsap.to(headerLogo, {
                    y: -90,
                    duration: 0.35,
                    ease: 'sine.in'
                });
            } else {
                gsap.to(headerLogo, {
                    y: 0,
                    duration: 0.35,
                    ease: 'sine.out'
                });
            }
        }
    };

    const onResize = () => {
        footerRect = footerLogo.getBoundingClientRect();
        if (footerRect.y <= window.innerHeight) {
            gsap.set(headerLogo, { y: 0 });
        }
    };

    const init = () => {
        Viewport.on('resize', onResize);
        onResize();
        observer = new IntersectionObserver(onIntersectionChange);
        observer.observe(footerLogo);
    };

    const destroy = () => {
        if (observer) {
            observer.unobserve(footerLogo);
            observer = null;
        }
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
