import serialize from 'form-serialize';
import $ from '../core/Dom';
import agent from '../core/request';

export default el => {
    const dom = $(el);

    const field = dom.find('[data-field]');
    const successMessage = dom.find('[data-success]');
    const errorMessage = dom.find('[data-error]');

    let timeoutId = 0;

    const hideMessages = () => {
        clearTimeout(timeoutId);
        successMessage.attr('aria-hidden', 'true');
        errorMessage.attr('aria-hidden', 'true');
    };

    const onSubmit = e => {
        e.preventDefault();
        agent
            .post('/')
            .accept('application/json')
            .send(serialize(e.target))
            .end((error, res) => {
                if (error) {
                    console.log(error);
                    errorMessage.attr('aria-hidden', 'false');
                    errorMessage.get(0).focus();
                } else {
                    const response = JSON.parse(res.text);
                    if (response.status === 'subscribed' || response.title === 'Member Exists') {
                        el.reset();
                        successMessage.attr('aria-hidden', 'false');
                        successMessage.get(0).focus();
                    } else {
                        errorMessage.attr('aria-hidden', 'false');
                        errorMessage.get(0).focus();
                    }
                }
                clearTimeout(timeoutId);
                timeoutId = setTimeout(hideMessages, 3000);
            });
    };

    const init = () => {
        dom.on('submit', onSubmit);
        field.on('focus', hideMessages);
    };

    const destroy = () => {
        dom.off('submit', onSubmit);
        field.off('focus', hideMessages);
    };

    return {
        init,
        destroy
    };
};
