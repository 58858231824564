import { gsap, Cubic } from 'gsap';
import $ from '../core/Dom';

import shouldAnimate, { onReducedMotion } from '../lib/ReducedMotion';

const init = () => {
    $('[data-scroll-target]')
        .on('click', e => {
            const href = e.triggerTarget.getAttribute('href');
            if (href && href.indexOf('#') !== -1) {
                const target = $(href);
                if (target.length) {
                    const offset = target.position().top - 48;
                    e.preventDefault();
                    gsap.to(window, {
                        duration: shouldAnimate() ? 1 : 0.05,
                        scrollTo: {
                            y: offset,
                            autoKill: false
                        },
                        ease: Cubic.easeInOut,
                        onComplete: () => {
                            target.get(0).focus();
                            window.scrollTo(0, offset);
                        }
                    });
                }
            }
        });
};

export default {
    init
};
