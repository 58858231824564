import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { IMAGE_GALLERY_OPEN, IMAGE_GALLERY_CLOSE } from '../lib/events';
import Viewport from '../core/Viewport';

const loadFlickity = require('bundle-loader?lazy&name=[name]!flickity');

export default el => {
    const dom = $(el);
    const body = $('body');
    const closeButton = dom.find('[data-close]');
    const wrapper = dom.find('[data-container]');
    const prev = dom.find('[data-prev]');
    const next = dom.find('[data-next]');
    const current = dom.find('[data-current]');
    const total = dom.find('[data-total]');

    let flkty = null;
    let slides = null;
    let triggerButton = null;

    const fixAriaHidden = () => {
        if (flkty) {
            flkty.cells.forEach(({ element }) => {
                element.removeAttribute('aria-hidden');
            });
        }
    };

    const checkStatus = () => {
        if (flkty) {
            current.text(flkty.selectedIndex + 1);
            total.text(flkty.cells.length);
            prev.attr('disabled', flkty.prevButton.isEnabled ? null : 'disabled');
            next.attr('disabled', flkty.nextButton.isEnabled ? null : 'disabled');
            fixAriaHidden();
        }
    };

    const initSlider = Flickity => {
        console.log('initSlider');
        slides = wrapper.find('[data-slide]');
        flkty = new Flickity(wrapper.get(0), {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'left',
            prevNextButtons: true,
            pageDots: false,
            wrapAround: false
        });

        flkty.on('dragStart', () => {
            document.ontouchmove = e => e.preventDefault();
            flkty.slider.style.pointerEvents = 'none';
        });

        flkty.on('dragEnd', () => {
            document.ontouchmove = () => true;
            flkty.slider.style.pointerEvents = 'auto';
        });

        prev.on('click', () => {
            flkty.previous();
        });

        next.on('click', () => {
            flkty.next();
        });

        flkty.on('select', checkStatus);
        flkty.on('ready', checkStatus);

        slides.on('focusin', e => {
            wrapper.get(0).parentNode.scrollLeft = 0;
            const { triggerTarget: link } = e;
            const cell = flkty.cells.find(({ element }) => element.contains(link));
            if (!cell) {
                return;
            }
            const index = flkty.cells.indexOf(cell);
            if (index > -1) {
                flkty.selectCell(index);
            }
        });

        dom.find('.flickity-prev-next-button').attr('tabIndex', -1);

        checkStatus();
    };

    const destroySlider = () => {
        if (flkty) {
            slides.off('focusin');
            prev.off('click');
            next.off('click');
            flkty.off('select', checkStatus);
            flkty.off('ready', checkStatus);
            flkty.off('dragStart');
            flkty.off('dragEnd');
            flkty.destroy();
            flkty = null;
            wrapper.html('');
        }
    };

    const onBodyKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            onClose();
        }
    };

    const onOpen = (key, data) => {
        console.log('ImageGalleryModal.onOpen', data);
        loadFlickity(Flickity => {
            triggerButton = data.triggerButton;
            wrapper.html(data.html);
            dom.attr('aria-hidden', null);
            Viewport.lockTabbing(el, closeButton);
            body.on('keyup', onBodyKeyUp);
            initSlider(Flickity);
        });
    };

    const onClose = () => {
        console.log('ImageGalleryModal.onClose');
        destroySlider();
        dom.attr('aria-hidden', 'true');
        body.off('keyup', onBodyKeyUp);
        Viewport.releaseTabbing(triggerButton);
    };

    const init = () => {
        closeButton.on('click', onClose);
        Dispatch.on(IMAGE_GALLERY_OPEN, onOpen);
        Dispatch.on(IMAGE_GALLERY_CLOSE, onClose);
    };

    const destroy = () => {
        destroySlider();
        closeButton.off('click', onClose);
        Dispatch.off(IMAGE_GALLERY_OPEN, onOpen);
        Dispatch.off(IMAGE_GALLERY_CLOSE, onClose);
    };

    return {
        init,
        destroy
    };
};
