import $ from '../core/Dom';

export default el => {
    const button = $(el);
    const body = $('body');

    const onBodyKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            toggle();
        }
    };

    const toggle = e => {
        const shouldOpen = button.attr('aria-expanded') === 'false';
        button.attr('aria-expanded', shouldOpen ? 'true' : 'false');
        if (shouldOpen) {
            body.on('keyup', onBodyKeyUp);
        } else {
            body.off('keyup', onBodyKeyUp);
        }
    };

    const init = () => {
        button.on('click', toggle);
    };

    const destroy = () => {
        button.off('click', toggle);
    };

    return {
        init,
        destroy
    };
};
