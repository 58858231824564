import $ from '../core/Dom';

export default el => {
    const dom = $(el);

    const onInvert = () => {
        dom.addClass('inverted');
    };

    const onUnInvert = () => {
        dom.removeClass('inverted');
    };

    const init = () => {
        dom.on('mouseenter', onInvert);
        dom.on('mouseleave', onUnInvert);
        dom.on('focusin', onInvert);
    };

    const destroy = () => {
        dom.off('mouseenter', onInvert);
        dom.off('mouseleave', onUnInvert);
        dom.off('focusin', onInvert);
    };

    return {
        init,
        destroy
    };
};
