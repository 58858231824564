import gsap from 'gsap';
import { Quad } from 'gsap/gsap-core';
import $ from '../core/Dom';

export default el => {
    const dom = $(el);
    const slides = dom.find('[data-slide]').nodes;
    const numberOfSlides = slides.length;

    let swapInterval = null;

    const swap = () => {

        const currentSlide = slides[numberOfSlides - 1];
        slides.unshift(slides.pop());
        const nextSlide = slides[numberOfSlides - 1];

        gsap.set(slides, { zIndex: 0 });
        gsap.set(currentSlide, { zIndex: numberOfSlides + 1 });
        gsap.set(nextSlide, { opacity: 1, zIndex: numberOfSlides });
        gsap.to(currentSlide, { opacity: 0, duration: 0.75, ease: 'power2.inOut' });
    };

    const init = () => {
        swapInterval = setInterval(swap, 4500);
    };

    const destroy = () => {
        clearInterval(swapInterval);
    };

    return {
        init,
        destroy
    };
};
