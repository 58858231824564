import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { SPLASH_COMPLETE } from '../lib/events';

export default (el, props) => {
    const dom = $(el);

    const parallax = dom.find('[data-parallax]').get();
    const fadeOuts = dom.find('[data-fade-out]').get();
    const fadeIns = dom.find('[data-fade-in]').get();
    const heading = dom.find('[data-heading]').get();
    const text = dom.find('[data-text]').get();

    const hasSplashScreen = $('[data-component="SplashScreen"]').get(0);

    const factor = (props || {}).factor || 1.7;

    let stageH;
    let timeline;

    const createTimeline = () => {
        timeline = gsap.timeline({
            paused: true,
            useFrames: true
        });

        if (fadeOuts.length) {
            timeline
                .fromTo(fadeOuts, {
                    y: 0,
                    opacity: 1
                }, {
                    duration: stageH * 0.5,
                    opacity: 0,
                    ease: 'linear'
                }, 0)
                .to(fadeOuts, {
                    duration: stageH * 0.5,
                    y: stageH * 0.2,
                    ease: 'linear'
                }, 0);
        }
        if (fadeIns.length) {
            timeline
                .to(fadeIns, {
                    duration: stageH,
                    opacity: 0.75,
                    ease: 'linear'
                }, 0);
        }
        if (parallax.length) {
            timeline
                .fromTo(parallax, {
                    yPercent: 0,
                    scale: 1
                }, {
                    duration: stageH,
                    yPercent: ((stageH * factor) / 100),
                    scale: 1.15,
                    ease: 'linear'
                }, 0);
        }
    };

    const onScroll = () => {
        timeline.progress(Math.max(0, Math.min(Viewport.scrollTop / timeline.duration(), 1)));
    };

    const onResize = () => {
        stageH = Viewport.height;
        if (timeline) {
            timeline.kill();
        }
        createTimeline();
        onScroll();
    };

    const doInit = () => {
        const initialDelay = hasSplashScreen ? 0 : 0.5;
        gsap.to(heading, { duration: 0.75, delay: initialDelay, opacity: 1, ease: 'power2.inOut' });
        gsap.to(heading, { duration: 0.75, delay: initialDelay + 1.25, y: 0, ease: 'power2.inOut' });
        gsap.to(text, { duration: 0.75, delay: initialDelay + 1.25, opacity: 1, y: 0, ease: 'power2.inOut' });
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
        onResize();
        onScroll();
    };

    const init = () => {
        if (hasSplashScreen) {
            Dispatch.on(SPLASH_COMPLETE, doInit);
        } else {
            doInit();
        }
    };

    const destroy = () => {
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
        Dispatch.off(SPLASH_COMPLETE, doInit);
    };

    return {
        init,
        destroy
    };
};
