const lerp = (a, b, alpha) => {
    if (typeof a === 'number' && typeof b === 'number') {
        return a + alpha * (b - a);
    } else if (typeof a === 'object' && typeof b === 'object') {
        let result = {};
        for (let key in a) {
            if (a.hasOwnProperty(key) && b.hasOwnProperty(key)) {
                result[key] = a[key] + alpha * (b[key] - a[key]);
            }
        }
        return result;
    } else {
        throw new Error('Inputs must be both numbers or both objects with matching keys');
    }
};

const clamp = (input, min, max) => {
    return input < min ? min : input > max ? max : input;
};

const EPSILON = 2.2204460492503130808472633361816e-3;

export class ParallaxOffset {
    constructor(svg, options = {}) {
        this.svg = svg;

        const defaultOptions = {
            offsetAmplitude: 0.1,
            monogramShiftIntensity: 0.1,
            targetSmoothing: 0.05,
            maxLineOpacity: 1.0,
            viewboxScaling: 1.0
        };

        this.options = { ...defaultOptions, ...options };

        this.offset = {
            x: 0,
            y: 0
        };
        this.offsetTarget = {
            x: 0,
            y: 0
        };

        // Sub-Paths
        this.offsetElements = svg.querySelectorAll('path');
        this.offsetElements.forEach((elem, index) => {
            let reversedIndex = this.offsetElements.length - 1 - index;
            let normalizedIndex = reversedIndex / this.offsetElements.length;

            elem.setAttribute('index', reversedIndex);
            elem.setAttribute('normalizedIndex', normalizedIndex);
            elem.setAttribute('shape-rendering', 'geometricPrecision');

            let t = 1.0 - normalizedIndex;
            elem.style['stroke-opacity'] = t * this.options.maxLineOpacity;
        });

        // ViewBox Scaling
        let [x, y, w, h] = this.svg.getAttribute('viewBox')
            .split(' ')
            .map(Number);
        let viewW = w;
        let viewH = h;
        let scale = this.options.viewboxScaling;
        let scaledW = viewW / scale;
        let scaledH = viewH / scale;
        let scaledX = (w - scaledW) / 2;
        let scaledY = (h - scaledH) / 2;

        this.svg.setAttribute('viewBox', `${scaledX} ${scaledY} ${scaledW} ${scaledH}`);

        // Events
        this.svg.addEventListener('mousedown', (e) => this.onMouseDown(e));
        this.svg.addEventListener('mouseenter', (e) => this.onMouseEnter(e));
        this.svg.addEventListener('mousemove', (e) => this.onMouseMove(e));
        this.svg.addEventListener('mouseleave', (e) => this.onMouseLeave(e));

        window.addEventListener('deviceorientation', (e) => this.onTilt(e), true);

        this.initialDeviceOrientation = null;
        this.svg.addEventListener('click', (e) => {
            if (window.DeviceMotionEvent && typeof DeviceMotionEvent.requestPermission === 'function') {
                DeviceMotionEvent.requestPermission()
                    .catch(console.error);
            }
        });

        // Animation Loop
        this.lastFrameTime = 0.0;
        this.animationFrameID = requestAnimationFrame(this.animate);
    }

    animate = (time) => {
        let deltaTime = (time - this.lastFrameTime) / 1000.0;

        let runAnimation =
            Math.abs(this.offset.x) > EPSILON ||
            Math.abs(this.offset.y) > EPSILON ||
            Math.abs(this.offsetTarget.x) > EPSILON ||
            Math.abs(this.offsetTarget.y) > EPSILON;

        // Stop loop when offset is really small
        if (runAnimation) {
            let smoothing = 1.0 - Math.pow(this.options.targetSmoothing, deltaTime);

            this.offset = lerp(this.offset, this.offsetTarget, smoothing);

            let actualShift = this.options.offsetAmplitude * this.svg.getAttribute('width');

            let monogramOffsetX = this.offset.x * actualShift * this.options.monogramShiftIntensity;
            let monogramOffsetY = this.offset.y * actualShift * this.options.monogramShiftIntensity;

            this.offsetElements.forEach((elem, index) => {
                let t = elem.getAttribute('normalizedIndex');

                elem.setAttribute(
                    'transform',
                    `translate(
        ${this.offset.x * -actualShift * t + monogramOffsetX}
        ${this.offset.y * -actualShift * t + monogramOffsetY}
        )`
                );
            });
        }

        this.lastFrameTime = time;
        this.animationFrameID = requestAnimationFrame(this.animate);
    };

    onMouseDown(e) {
        this.initialDeviceOrientation === null;
    }

    onMouseEnter(e) {
    }

    onMouseMove(e) {
        const rect = e.target.getBoundingClientRect();

        this.offsetTarget.x = ((event.clientX - rect.left) / rect.width - 0.5) * 2.0;
        this.offsetTarget.y = ((event.clientY - rect.top) / rect.height - 0.5) * 2.0;
    }

    onMouseLeave(e) {
        this.offsetTarget = {
            x: 0,
            y: 0
        };
    }

    onTilt(e) {
        let {
            alpha,
            beta,
            gamma
        } = e;

        if (this.initialDeviceOrientation === null) {
            this.initialDeviceOrientation = {
                alpha,
                beta,
                gamma
            };
        }

        let actualOrientation = {
            alpha: alpha - this.initialDeviceOrientation.alpha,
            beta: beta - this.initialDeviceOrientation.beta,
            gamma: gamma - this.initialDeviceOrientation.gamma
        };

        const normalizedLong = clamp((actualOrientation.gamma / 5) * 1, -1, 1);
        const normalizedShort = clamp(actualOrientation.beta / 5, -1, 1);

        this.offsetTarget = {
            x: normalizedLong,
            y: normalizedShort
        };
    }
}
