import gsap from 'gsap';
import { Quint } from 'gsap/gsap-core';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default el => {
    const dom = $(el);
    const body = $('body');
    const header = dom.find('[data-header]');
    const menu = dom.find('[data-menu]').get(0);
    const menuLabel = dom.find('[data-menu-label]').get(0);
    const hamburger = dom.find('[data-hamburger]');

    const menuItems = dom.find('[data-menu-item]');
    const menuPreviews = dom.find('[data-preview]');

    const killAnimItems = [menu];

    let menuOpen = false;
    let scrollbarWidth = -1;
    let menuHideId = -1;
    let lastPreviewId = null;
    let current = null;

    const getFocusableElements = () => [
        dom.find('[data-menu]').get(0),
        hamburger.get(0)
    ];

    const getScrollbarWidth = () => {
        if (scrollbarWidth === -1) {
            const initialBodyWidth = body.width();
            body.css('overflow', 'hidden');
            const fullBodyWidth = body.width();
            body.css('overflow', '');
            scrollbarWidth = fullBodyWidth - initialBodyWidth;
        }
        return scrollbarWidth;
    };

    const onBodyKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            toggleMenu();
        }
    };

    const openMenu = () => {
        hamburger.attr('aria-expanded', 'true');
        body.on('keyup', onBodyKeyUp).addClass('menu-open');
        gsap.killTweensOf(killAnimItems);
        Viewport.lockTabbing(getFocusableElements());
        if (menuLabel) {
            window.vrsg.setCookie('hide-menu-label', '1');
            gsap.to(menuLabel, {
                duration: 0.75,
                autoAlpha: 0,
                ease: Quint.easeOut,
                onComplete: () => {
                    menuLabel.parentElement.removeChild(menuLabel);
                }
            });
        }
        gsap.to(menu, {
            duration: 0.75,
            autoAlpha: 1,
            ease: Quint.easeOut,
            onComplete: () => {
                Viewport.lockScrolling();
                header.css('padding-right', `${getScrollbarWidth()}px`);
                $(menu).css({
                    overflow: 'auto',
                    'padding-right': `${getScrollbarWidth()}px`
                });
            }
        });
    };

    const closeMenu = () => {
        hamburger.attr('aria-expanded', 'false');
        body.off('keyup', onBodyKeyUp).removeClass('menu-open');
        gsap.killTweensOf(killAnimItems);
        gsap.to(menu, { duration: 0.5, autoAlpha: 0, ease: Quint.easeInOut });
        Viewport.releaseScrolling();
        Viewport.releaseTabbing(hamburger.get(0));
        $(menu).css({
            overflow: null,
            'padding-right': null
        });
        header.css('padding-right', null);
    };

    const toggleMenu = e => {
        if (e) {
            e.preventDefault();
        }
        if (menuOpen) {
            closeMenu();
        } else {
            openMenu();
        }
        menuOpen = !menuOpen;
    };

    const hidePreviews = () => {
        gsap.killTweensOf(menuPreviews.nodes);
        menuPreviews.addClass('hidden');
        lastPreviewId = null;
    };

    const onHidePreview = e => {
        clearTimeout(menuHideId);
        menuHideId = setTimeout(hidePreviews, 100);
    };

    const onShowPreview = e => {
        const target = $(e.triggerTarget);
        const previewId = `preview_${target.data('menu-item')}`;
        if (lastPreviewId !== previewId) {
            clearTimeout(menuHideId);
            hidePreviews();
            lastPreviewId = previewId;
            const previewImage = dom.find(`[data-preview="${previewId}"]`);
            if (previewImage.length) {
                gsap.set(menuPreviews.nodes, { opacity: 0 });
                previewImage.removeClass('hidden');
                gsap.to(previewImage.get(0), {
                    duration: 0.35,
                    opacity: 1,
                    ease: 'sine.in'
                });
            }
        }
    };

    const highlightCurrent = () => {
        const url = document.location.href;
        menuItems.each(node => {
            if (url.startsWith(node.href)) {
                $(node).addClass('text-ui-fg-negative-tertiary');
                onShowPreview({
                    triggerTarget: node
                });
                current = node;
            }
        });
    };

    const init = () => {
        highlightCurrent();
        hamburger.on('click', toggleMenu);
        menuItems.on('mouseenter', onShowPreview);
        menuItems.on('focusin', onShowPreview);
        menuItems.on('mouseleave', onHidePreview);
        menuItems.on('focusout', onHidePreview);
        getScrollbarWidth();
    };

    const destroy = () => {
        hamburger.off('click', toggleMenu);
        menuItems.off('mouseenter', onShowPreview);
        menuItems.off('focusin', onShowPreview);
        menuItems.off('mouseleave', onHidePreview);
        menuItems.off('focusout', onHidePreview);
        Viewport.releaseScrolling();
        Viewport.releaseTabbing(hamburger.get(0));
    };

    return {
        init,
        destroy
    };
};
