import { ParallaxOffset } from '../lib/ParallaxOffsetter';
import shouldAnimate from '../lib/ReducedMotion';

export default el => {

    let observer = null;

    const onIntersectionChange = entries => {
        if (entries.filter(entry => entry.isIntersecting).length) {
            observer.unobserve(el);
            new ParallaxOffset(el);
        }
    };

    const init = () => {
        if (shouldAnimate()) {
            observer = new IntersectionObserver(onIntersectionChange);
            observer.observe(el);
        }
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
