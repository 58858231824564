import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {
    const dom = $(el);
    const filters = dom.find('button[data-department]');
    const targets = dom.find('div[data-department]');

    const onFilter = e => {
        const department = $(e.target).data('department');
        if (department) {
            targets.each(node => {
                const target = $(node);
                target.toggleClass('!hidden', target.data('department').indexOf(department) === -1);
            });
        } else {
            targets.removeClass('!hidden');
        }
        filters.attr('aria-current', null);
        dom.find(`button[data-department="${department}"]`).attr('aria-current', 'true');
    };

    const init = () => {
        filters.on('click', onFilter);
    };

    const destroy = () => {
        filters.off('click', onFilter);
    };

    return {
        init,
        destroy
    };
};
